import gql from 'graphql-tag'

export const GET_POINTS_POOLING = gql`
    query PointsPooling($primaryInstallerId: String) {
        promotions_pointsPooling(primaryInstallerId: $primaryInstallerId) {
            id
            yearToDate
            previousYearToDate
            growthAmount
            growthPercentage
            isUp
            totalPoints
            # seasonTotals {
            #     spring {
            #         yearToDate
            #         previousYearToDate
            #         growthAmount
            #         growthPercentage
            #         isUp
            #         totalPoints
            #     }
            #     fall {
            #         yearToDate
            #         previousYearToDate
            #         growthAmount
            #         growthPercentage
            #         isUp
            #         totalPoints
            #     }
            # }
            # familyLines {
            #     promotionId
            #     yearToDate
            #     previousYearToDate
            #     growthAmount
            #     growthPercentage
            #     isUp
            #     basePoints
            #     bonusPoints
            #     totalPoints
            # }
            registration {
                id
                installerNavisionId
                installer {
                    id
                    businessName
                    address
                }
            }
        }
    }
`