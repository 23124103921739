import React, { useState } from 'react'
import './tabSelector.css'

function TabSelector({ onClick, tabs, style }) {

    const [ activeTab, setActiveTab ] = useState(0)

    return (
        <div className='tabSelectorContainer tabSelectorContainer--default' style={style}>
            {
                tabs && tabs.map((tab, i) => (
                    <TabSelectorButton key={i} active={ activeTab === i } onClick={() => { setActiveTab(i); onClick && onClick(tab[0]); }}>{ tab[1] }</TabSelectorButton>
                ))
            }
        </div>
    )

}

function TabSelectorButton({ children, active, onClick }) {
    return <button type='button' onClick={() => onClick && onClick()} className={`tabSelectorButton tabSelectorButton--${ active ? 'active' : 'inactive' }`}>{ children }</button>
}

export default TabSelector